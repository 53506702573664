@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: oxygen-light;
  src: url('./assets/fonts/oxygen-light.woff2');
}

@font-face {
  font-family: oxygen-regular;
  src: url('./assets/fonts/oxygen-regular.woff2');
}

@font-face {
  font-family: oxygen-bold;
  src: url('./assets/fonts/oxygen-bold.woff2');
}

@layer components {
  
  /* MenuLink */
  .menu__link {
    @apply px-8 lg:px-6 py-4 lg:py-2 border-b lg:hover:bg-brown-light lg:hover:border-b-brown-light lg:mr-6 lg:rounded lg:border-none lg:my-auto flex cursor-pointer;
  }

  /* LanguageSelect */
  .language-select__drop-container {
    @apply block absolute bg-white w-full lg:mt-2 border-t lg:border lg:rounded transition-all duration-100 ease-in-out
  }
  .language-select__button {
    @apply px-8 lg:px-6 py-4 lg:py-2 w-full lg:hover:bg-brown-light lg:border lg:rounded
  }

  /* PrimaryButton & SecondaryButton */
  .home-link {
    @apply lg:w-3/4 w-full h-16 font-bold rounded-full uppercase
  }

  /* Navbar */
  .navbar {
    @apply sticky top-0 z-50 border-b border-b-brown-main lg:px-4 lg:flex justify-between bg-white
  }
  .menu__negative-space {
    @apply bg-brown-main h-full bottom-0 left-0 w-full fixed transition-opacity duration-200 ease-in-out
  }
  .menu__container {
    @apply z-40 absolute lg:static w-full bg-white shadow-md lg:shadow-none flex flex-col lg:flex-row transition-all duration-200 ease-in-out my-auto
  }
  .menu__link-container {
    @apply lg:w-auto mr-0 lg:mr-6
  }
  .menu__breadcrumb-container {
    @apply justify-between
  }

  /* Page */
  .page {
    @apply flex flex-col h-screen font-light text-brown-main
  }
  .page__content {
    @apply mb-auto p-10 lg:px-40 lg:py-20 2xl:px-80 2xl:py-20 lg:grid gap-y-10 lg:gap-x-20 lg:gap-y-40
  }
}


